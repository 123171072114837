.headline.center {
  margin: 40px 0;
}

.counter-details {
  border: 0;
}

.counter-value {
  font-size: 32px;
}

.rev_slider_wrapper {
  height: 870px;
  margin-bottom: 30px;
}

.rev_slider {
  position: relative;
}

.rev_slider ul {
  list-style: none !important;
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: visible;
  overflow-y: visible;
  background-image: none;
  background-position: 0 0;
  text-indent: 0;
  top: 0;
  left: 0;
}

.full-section {
  padding: 2rem 0;
  margin-bottom: 2rem;
}

@media (max-width: 767px){
  body.home-page.padding-120 {
    padding-top: 120px;
  }
}